import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
// import LogoAnimation from "../Animations/LogoAnimation";
// import Login from "../login/Login";
import { getAuth, getDecryptMsisdn, getGuuid, getGuuidToken } from "../../ApiCalls/Auth/api_v1_auth";
import { useEffect, useState } from "react";
import Loader from '../Animations/Loader';
const useAuth = () => {
    const cookies = new Cookies();
    let isTokenAvailable = localStorage.getItem("token");
    let isUuid = cookies.get('uid');
    let u = cookies.get('u');
    let rmsid = cookies.get('rmsid');
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    let location = useLocation();
    const isIOSDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    const [loggedIn, setLoggedIn] = useState(null);
    useEffect(() => {
        if (isIOSDevice) {
            return;
        }
        // if (isUuid === null) {
        //     window.location.ref = "http://127.0.0.1:8000/0auth?uuid=1";
        // }
        // cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
        // setLoggedIn(true);
        // return;

        const fetchData = async () => {
            let auth;
            let msisdn;
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const aParam = urlParams.get("a");
                if (aParam) {
                    u = aParam;
                }
                const authenticateUser = await getDecryptMsisdn({ encMsisdn: u });
                // console.log(authenticateUser);
                if ('access_token' in authenticateUser.headers) {
                    const accessToken = authenticateUser.headers['access_token'];
                    if (accessToken !== null && accessToken !== undefined) {
                        localStorage.setItem('token', accessToken);
                    }
                }
                auth = authenticateUser.data.data.suv;
                msisdn = authenticateUser.data.data.msisdn;
            } catch {
                auth = 0;
                msisdn = 0;
            }
            if (auth === 1 && msisdn.toString().length === 12) {
                // Both auth is 1 and msisdn length is 12
                setLoggedIn(true);
            }
            // else if (msisdn.toString().length === 12 && (auth === 0 || auth === 1)) {
            //     // Either msisdn length is 12 and auth is 0 or msisdn length is 12 and auth is 1
            //     setLoggedIn(true);
            // } 
            else {
                setLoggedIn(false);
            }
        };
        // const fetchDtMsisdn = async () => {
        //     let msisdn;
        //     try {
        //         const authenticateUser = await getDecryptMsisdn({ encMsisdn: new URLSearchParams(window.location.search).get("user")});
        //         if ('access_token' in authenticateUser.headers) {
        //             const accessToken = authenticateUser.headers['access_token'];
        //             if (accessToken !== null && accessToken !== undefined) {
        //                 localStorage.setItem('token', accessToken);
        //             }
        //         }
        //         msisdn = authenticateUser.data.data.msisdn;
        //     } catch {
        //         msisdn = -1;
        //     }
        //     return msisdn;
        // };
        if (isTokenAvailable) {
            // if (isUuid == undefined) {
            //     cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
            // }
            // const decoded = jwt_decode(isTokenAvailable);
            // const msisdn = decoded.userInfo['msisdn'];
            // const auth = decoded.userInfo['auth'];
            fetchData();

        } else
        // if (isTokenAvailable == null && isUuid == null) 
        {
            const redirect = new URLSearchParams(window.location.search).get("r");
            if (redirect === null) {
                const queryParameters = window.location.search; // Get all query parameters
                const redirectURL = process.env.REACT_APP_SESSION_URL + queryParameters + (queryParameters ? "&uuid=-1" : "?uuid=-1");
                window.location.href = redirectURL;
                return;
            }
            cookies.set('clickid', new URLSearchParams(window.location.search).get("clickid"), { path: '/', expires: nextYear });
            cookies.set('cpid', new URLSearchParams(window.location.search).get("cpid"), { path: '/', expires: nextYear });
            cookies.set('base', new URLSearchParams(window.location.search).get("base"), { path: '/', expires: nextYear });
            cookies.set('uid', new URLSearchParams(window.location.search).get("user"), { path: '/', expires: nextYear });
            cookies.set('u', new URLSearchParams(window.location.search).get("uuid"), { path: '/', expires: nextYear });
            cookies.set('source', new URLSearchParams(window.location.search).get("source"), { path: '/', expires: nextYear });
            cookies.set('tc', new URLSearchParams(window.location.search).get("tc"), { path: '/', expires: nextYear });
            // const msisdn = fetchDtMsisdn();
            // console.log(msisdn);

            // -1 = wifi else mobile
            let guuidData = {
                "user": new URLSearchParams(window.location.search).get("user"),
                "h": new URLSearchParams(window.location.search).get("user"),
                "tc": parseInt(new URLSearchParams(window.location.search).get("tc")) || 101,
                "cpid": parseInt(new URLSearchParams(window.location.search).get("cpid")) || 0,
                "clickid": new URLSearchParams(window.location.search).get("clickid") ? new URLSearchParams(window.location.search).get("clickid") : 0,
                "uid": cookies.get('uid')
            }
            getGuuid(guuidData).then(function (res) {
                const msg = res.data.message;
                const stts = res.data.data.status;

                if (msg === "ok") {
                    const uid = res.data.data.uid;
                    const utype = res.data.data.u;
                    const rmsid = res.data.data.rmsid;

                    cookies.set('uid', uid, { path: '/', expires: nextYear });
                    cookies.set('u', utype, { path: '/', expires: nextYear });
                    cookies.set('rmsid', rmsid, { path: '/', expires: nextYear });

                    const accessToken = res.headers['access_token'];
                    localStorage.setItem('token', accessToken);

                    if (stts === 'subscriber') {
                        // login user
                        localStorage.setItem("dv", 3);
                        cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                        setLoggedIn(true);
                    } else if (stts === 'guest') {
                        // guest
                        cookies.remove('uid_exp');
                        setLoggedIn(true);
                    } else if (stts === 'expired') {
                        // expired
                        cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
                        setLoggedIn(false);
                    }
                }
            });


        }
        // else if (isUuid) {
        //     let tokenData = {
        //         uid: isUuid,
        //         type: utype,
        //         rmsid: rmsid,
        //     }
        //     const fetchData = async () => await getGuuidToken(tokenData).then(response => {
        //         if (response.status == 200 && response.data.status === "ok") {
        //             let accessToken = response.data.token;
        //             localStorage.setItem('token', accessToken);
        //             setLoggedIn(true);
        //         }
        //         else {
        //             setLoggedIn(false);
        //         }
        //     }).catch(error => {
        //         setLoggedIn(false);
        //     })
        //     fetchData();
        // }
        // else {
        //     let isToken = localStorage.getItem("token");
        //     let tokenData = {
        //         token: isToken
        //     }
        // const fetchData = async () => await getAuth(tokenData).then(response => {
        //     if (response.status == 200 && response.data.status === "ok" || response.data.status === "refresh") {
        //         if (response.data.status === "refresh") {
        //             let accessToken = response.data.token;
        //             localStorage.setItem('token', accessToken);
        //         }
        //         setLoggedIn(true);
        //     }
        //     else {
        //         // console.log("ss",loggedIn)
        //         setLoggedIn(false);
        //     }
        // }).catch(error => {
        //     // localStorage.removeItem('token');
        //     setLoggedIn(false);
        // })
        //     fetchData();
        // }

    }, [location.pathname])
    return loggedIn
}


const ProtectedRoutes = () => {
    let location = useLocation();
    const cookies = new Cookies();
    const isAuth = useAuth();
    let isUuidExp = cookies.get('uid_exp');
    const isIOSDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    // console.log(isAuth);
    if (isIOSDevice) {
        document.body.style.backgroundColor = "#3d3c3a";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#3d3c3a");
        document.title = "Oops! Service Only For Android | Ding";
        document.body.innerHTML = `
          <div style="display: flex; align-items: center; justify-content: center; height: 100vh; text-align: center;">
            <div style="background-color: black; color: white; padding: 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
              <h2 style="font-size: 24px; margin-bottom: 10px;font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;">Oops! This Web App is for<br/>Android Only</h2>
              <p style="font-size: 12px;line-height: 19px;font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;">We apologize, but this web app is designed for Android devices.<br/>For the best experience, please use it on an Android device.</p>
            </div>
          </div>
        `;
        return;
    }
    if (isAuth === null) // waiting..
    { return <Loader />; }
    // <LogoAnimation />;

    // if(isAuth &&  location.pathname==="/" || location.pathname==="/login")
    // {
    //     // console.log(location.pathname);    
    //      return <Navigate to="/home" />
    // }
    if (location.pathname === "/share/watch") {
        // console.log(location.pathname);    
        return <Outlet />
    }
    return isUuidExp == 1 ? <Navigate to="/login?exp=1" /> : isAuth ? <Outlet /> : <Navigate to="/login" />
    // <Navigate to="/" />
    // <Login />
}


export default React.memo(ProtectedRoutes);