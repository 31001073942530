import { useEffect, useLayoutEffect, useRef } from "react";
import { consentResult } from "../../ApiCalls/LoginAuthenticateApi/api_v1_login_validate";
import Cookies from "universal-cookie";
import Loader from "../Animations/Loader";
import { useNavigate } from "react-router-dom";
// import { useLocation, } from "react-router-dom";

function Consent() {
    const respCode = (new URLSearchParams(window.location.search)).get("respCode");
    const cid = (new URLSearchParams(window.location.search)).get("cid");
    const cookies = new Cookies();
    const currentD = new Date();
    const nextYear = new Date();
    const navigate = useNavigate();
    nextYear.setFullYear(currentD.getFullYear() + 1);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Consent | Ding";
        if (isIOSDevice) {
            document.body.style.backgroundColor = "#3d3c3a";
            document.querySelector("meta[name='theme-color']").setAttribute("content", "#3d3c3a");
            document.title = "Oops! Service Only For Android | Ding";
            document.body.innerHTML = `
                  <div style="display: flex; align-items: center; justify-content: center; height: 100vh; text-align: center;">
                    <div style="background-color: black; color: white; padding: 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
                      <h2 style="font-size: 24px; margin-bottom: 10px;font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;">Oops! This Web App is for<br/>Android Only</h2>
                      <p style="font-size: 12px;line-height: 19px;font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;">We apologize, but this web app is designed for Android devices.<br/>For the best experience, please use it on an Android device.</p>
                    </div>
                  </div>
                `;
            return;
        }
    }, []);
    // const location = useLocation();
    const isIOSDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    // useEffect(() => {
    //     if (isIOSDevice) {
    //         return;
    //     }
    //     // Get all query parameters from the current URL
    //     const queryParams = new URLSearchParams(location.search);

    //     // Example: Extracting a specific parameter named 'vid'
    //     const respCode = queryParams.get('respCode');
    //     const cid = queryParams.get('cid');

    //     // Construct the new URL with the parameters you need
    //     const redirectToUrl = `https://telenor.ding.pk/consent?respCode=${respCode}&cid=${cid}`;
    //     // Redirect to the new domain
    //     window.location.href = redirectToUrl;
    // }, [location.search]);
    useEffect(() => {
        if (isIOSDevice) {
            return;
        }
        let reqPayload = {
            "uuid": cookies.get('u'),
            "cid": cid,
            "respCode": respCode
        }
        consentResult(reqPayload).then(
            (res) => {
                // console.log(res.data.message, res.data);
                if (res.data.message === "ok") {
                    const accessToken = res.headers['access_token'];
                    localStorage.setItem('token', accessToken);
                    const suv = res.data.data.suv;
                    const ndnc = res.data.data.ndnc;
                    if (suv == 1) {
                        cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                        if (ndnc == 4) {
                            navigate('/login/thankyou');
                        }
                        else {
                            setTimeout(
                                () => navigate('/home'),
                                1000
                            );
                        }

                    }

                } else {
                    navigate('/login?r=1', { state: { consentState: true, respMsg: "we can not process your request at this time please try later" } });
                }
            }
        ).catch((e) => {
            // console.log(e.response.data.data.suv, e.response.data)
            if (e.response.data.data.suv === 0 && e.response.data.message !== "") {
                // console.log(e.response.data.message)
                navigate('/login?r=1', { state: { consentState: true, respMsg: e.response.data.message } });
            } else {
                navigate('/login?r=1', { state: { consentState: true, respMsg: "we can not process your request at this time please try later" } });
            }
        });
    }, []);
    return (
        <Loader />

    )
}

export default Consent;