import { useEffect, useLayoutEffect } from "react";
import Cookies from "universal-cookie";
import Loader from "../Animations/Loader";

function PreSaveData() {
    const cookies = new Cookies();
    const currentD = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(currentD.getFullYear() + 1);

    const urlParams = new URLSearchParams(window.location.search);
    const cmsToken = encodeURIComponent(urlParams.get("cmt"));
    const userValue = urlParams.get("a");

    cookies.set("u", userValue, { path: "/", expires: nextYear });

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Consent | Ding";
    }, []);

    useEffect(() => {
        if (cmsToken) {
            window.location.href = process.env.REACT_APP_TelenorCmsUrl + cmsToken;
        }
    }, [cmsToken]);

    return <Loader />;
}

export default PreSaveData;
